<template>
  <div class="content">
    <app-cells position="between">
      <app-cells :indent="false">
        <h1 class="title title--theme">Участники</h1>
        <router-link v-if="userIsAdmin" :to="{ name: 'members-stat' }" class="btn btn--link">
          <img src="@/assets/img/sidebar/stat.svg" alt="Stat icon">
          <span>Статистика</span>
        </router-link>
      </app-cells>
      <v-select
        v-if="userIsAdmin"
        :reduce="item => item.id"
        :options="delegation_options"
        :filterable="true"
        :clearable="false"
        v-model="user_delegation_id"
        @input="onSetDelegation"
        label="name"
        placeholder="Выберите делегацию"
        class="select select--minw"
      >
        <template #open-indicator>
          <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
            <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
          </svg>
        </template>
        <template #option="option">
          <div class="select__item d-center">{{ option.name }}</div>
        </template>
        <template #selected-option="option">
          <div class="selected d-center">{{ option.name }}</div>
        </template>
        <template #no-options>Ничего не найдено</template>
      </v-select>
    </app-cells>
    <app-cells v-if="!userIsAdmin && delegation_settings.participants_done" position="start">
      <div class="notice" :class="{'notice--yellow': !delegation_settings.participants_print, 'notice--green': delegation_settings.participants_print,}">
        <div class="notice__title">
          <img v-if="!delegation_settings.participants_print" src="@/assets/img/info-icon-yellow.svg" alt="Info icon">
          <img v-else src="@/assets/img/info-icon-green.svg" alt="Info icon">
          <span>Информация</span>
        </div>
        <p v-if="!delegation_settings.participants_print" class="notice__text">
          Ваша делегация находится на проверке регламентной службой. Добавление новых участников - недоступно. После проверки у вас появится возможность скачать сформированную заявку делегации.
        </p>
        <p v-else class="notice__text">
          Ваша делегация проверена регламентной службой. Вы можете скачать заявку делегации.
        </p>
      </div>
    </app-cells>
    <app-cells v-if="!userIsAdmin && (!delegation_settings.participants_done || delegation_settings.participants_print)" position="between">
      <app-cells position="start" :indent="false">
        <template v-if="!delegation_settings.participants_done">
          <router-link  :to="{ name: 'members-create' }" class="btn btn--link btn--link-icon">
            <span>Добавить участника</span>
          </router-link>
          <app-button size="link" @click="onCopyLinkToClipboard">
            <img src="@/assets/img/link-icon.svg" alt="link icon">
            <span>Ссылка на анкету</span>
          </app-button>
        </template>
        <app-button
          v-if="delegation_settings.participants_print"
          @click="onDownloadApplication"
          size="link"
          :class="{'btn--loading': download_app}"
        >
          <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
          <span>Скачать заявку делегации</span>
        </app-button>
        <app-button
          size="link"
          :class="{'btn--loading': download_cert}"
          @click="onDownloadCert"
        >
          <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
          <span>Сертификаты участников</span>
        </app-button>
      </app-cells>
      <app-button
        v-if="!delegation_settings.participants_done"
        @click="onShowModal"
        size="small"
      >
        Отправить на проверку
      </app-button>
    </app-cells>
    <app-cells v-if="userIsAdmin" position="start">
      <app-checkbox
        v-model="delegation_settings.participants_done"
        @change="onChangeDelegationSettings({participants_done: delegation_settings.participants_done}, false, true)"
        label="Делегация сформирована"
      />
      <app-checkbox
        v-model="delegation_settings.participants_print"
        @change="onChangeDelegationSettings({participants_print: delegation_settings.participants_print}, false)"
        label="Разрешить печать заявки"
      />
      <app-button
        @click="onDownloadApplication"
        size="link"
        :class="{'btn--loading': download_app}"
      >
        <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
        <span>Скачать заявку делегации</span>
      </app-button>
      <app-button
        size="link"
        :class="{'btn--loading': download_cert}"
        @click="onDownloadCert"
      >
        <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
        <span>Сертификаты участников</span>
      </app-button>
    </app-cells>
    <app-table-head
      @update-limiter="onUpdateLimiter"
      :count="table.items.length"
      :page="pagination.page"
      :all="pagination.count"
    />
    <app-cells position="start">
      <v-select
        @search="onFilterSearch"
        noDrop
        label="id"
        placeholder="Поиск по ФИО или ID"
        class="select select--minw"
      />
      <v-select
        :reduce="item => item.code"
        :options="status_options"
        :filterable="true"
        :clearable="true"
        :searchable="false"
        v-model="status_code"
        @input="onChangeStatus"
        label="name"
        placeholder="Выберите статус"
        class="select select--minw"
      >
        <template #open-indicator>
          <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
            <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
          </svg>
        </template>
        <template #option="option">
          <div class="select__item d-center">{{ option.name }}</div>
        </template>
        <template #selected-option="option">
          <div class="selected d-center">{{ option.name }}</div>
        </template>
        <template #no-options>Ничего не найдено</template>
      </v-select>
    </app-cells>
    <v-client-table
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="tableDefault"
      class="table-default table-default--dynamic table-default--fix-links"
    >
      <template #pk="props">
        <app-table-cell-link  :link="$router.resolve({name: 'members-detail', params: {id: props.row.pk}}).href">
          {{ props.row.pk }}
        </app-table-cell-link>
      </template>
      <div slot="last_name" slot-scope="props" class="table-default__left table-default_full" >
        <app-table-cell-link  :link="$router.resolve({name: 'members-detail', params: {id: props.row.pk}}).href">
          {{ props.row.last_name }} {{ props.row.first_name }} {{ props.row.patronymic ? props.row.patronymic : '' }}
        </app-table-cell-link>
      </div>
      <template #gender_obj="props">
        <app-table-cell-link  :link="$router.resolve({name: 'members-detail', params: {id: props.row.pk}}).href">
        {{ props.row.gender_obj.name }}
        </app-table-cell-link>
      </template>
      <template #birthday="props">
        <app-table-cell-link  :link="$router.resolve({name: 'members-detail', params: {id: props.row.pk}}).href">
        <span>{{ formatDate(props.row.birthday) }}</span>
        <span
          class="red"
          :class="{
            'green': getAge(props.row.age) >= 18 && getAge(props.row.age) <= 35,
            'yellow': getAge(props.row.age) > 35 || (getAge(props.row.age) < 18 && getAge(props.row.age) >= 16)
          }"
        >
          {{ props.row.age }}
        </span>
        </app-table-cell-link>
      </template>
      <template #participation_status_name="props">
        <app-table-cell-link  :link="$router.resolve({name: 'members-detail', params: {id: props.row.pk}}).href">
          {{ props.row.participation_status_name }}
        </app-table-cell-link>
      </template>
      <template #status_obj="props">
        <app-table-cell-link  :link="$router.resolve({name: 'members-detail', params: {id: props.row.pk}}).href">
        <span
          :class="{
            'green': props.row.status_obj.code === 'accept',
            'red': props.row.status_obj.code === 'reject',
            'yellow': props.row.status_obj.code === 'review',
            'grey': props.row.status_obj.code === 'draft'
          }"
        >
          {{ props.row.status_obj.name }}
        </span>
        </app-table-cell-link>
      </template>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="onClickPagination"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    />
    <modal
      name="send-review-modal"
      :width="750"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="modal">
        <div class="modal__title modal__title--center-black">Подтверждение действия</div>
        <p class="modal__text">
          Вы уверены, что хотите отправить список участников на проверку и завершить формирование
          делегации? Вы не сможете добавлять новых участников. Это действие сможет отменить только регламентная служба
        </p>
        <app-cells position="center">
          <app-button size="small" @click="onChangeDelegationSettings({participants_done: true}, true)" ref="reviewSubmit">Да</app-button>
          <app-button size="small" @click="onHideModal">Нет</app-button>
        </app-cells>
      </div>
    </modal>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import {
  getMembersList,
  getDelegations,
  patchUser,
  getStatus,
  getDelegationSettings,
  patchDelegationSettings,
  getMembersExport, getFile, getThankNotesExport, getCertificatesParticipantsExport
} from '@/http'
import { dateFormatting } from '@/helpers'
import AppTableCellLink from '@/components/AppTableCellLink.vue'

export default {
  name: 'MembersList',
  components: {AppTableCellLink},
  data() {
    return {
      table: {
        items: [],
        columns: ['pk', 'last_name', 'gender_obj', 'birthday', 'participation_status_name', 'status_obj'],
        options: {
          headings: {
            pk: 'ID',
            last_name: 'ФИО',
            gender_obj: 'Пол',
            birthday: 'Дата рождения',
            participation_status_name: 'Категория',
            status_obj: 'Статус',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одного участника',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          // rowClassCallback: row => `table-default__row table-default__row--${row.status}`,
          rowClassCallback: row => 'table-default__row',

        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
      },
      table_limit: 20,
      delegation_options: [],
      user_delegation_id: null,
      status_options: [],
      status_code: '',
      delegation_settings: {},
      download_app: false,
      download_file: false,
      download_cert: false,
      delegation_name: '',
      site_url: process.env.VUE_APP_SITE_URL,
    }
  },
  computed: {
    userIsAdmin() {
      return this.$store.state.user_role === 'admin'
    },
    userIsHead() {
      return this.$store.state.user_role === 'head'
    },
  },
  created() {
    this.fetchItems()
    this.fetchDelegationsSettings()
    getStatus()
      .then(response => {
        this.status_options = response.data
        if (this.userIsAdmin) {
          getDelegations()
            .then(response => {
              this.delegation_options = response.data
              this.user_delegation_id = this.$store.state.delegation
              if (this.$route.params.delegation_id) {
                this.user_delegation_id = this.$route.params.delegation_id
                this.onSetDelegation()
              } else {
                this.delegation_name = this.delegation_options.find(item => item.id === this.user_delegation_id).name
              }
            })
        } else {
          getDelegations()
            .then(response => {
              this.delegation_options = response.data
              this.delegation_name = this.delegation_options.find(item => item.id === this.$store.state.delegation).name
            })
        }
      })
  },
  methods: {
    fetchDelegationsSettings() {
      getDelegationSettings()
        .then(response => {
          this.delegation_settings = response.data
        })
    },
    fetchItems(page = this.pagination.page, page_size = this.table_limit, search = '', status__code = this.status_code) {
      if (search) status__code = ''
      getMembersList({page, page_size, search, status__code})
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
    },
    onChangeStatus() {
      this.fetchItems()
    },
    onDownloadApplication() {
      this.download_app = true
      getMembersExport()
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Заявка на участие - Участники ${this.delegation_name}.pdf`)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .finally(() => {
          this.download_app = false
        })
    },
    onDownloadCert() {
      this.download_cert = true
      const delegation = this.delegation_options.find(item => item.id === this.user_delegation_id)
      getCertificatesParticipantsExport()
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Сертификаты участников ${delegation.name}.zip`)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .finally(() => {
          this.download_cert = false
        })
    },
    onShowModal() {
      this.$modal.show('send-review-modal')
    },
    onHideModal() {
      this.$modal.hide('send-review-modal')
    },
    onChangeDelegationSettings(data, withModal, participantsDone = false) {
      if (withModal) this.$refs.reviewSubmit.preload = true
      patchDelegationSettings(data)
        .finally(() => {
          this.fetchDelegationsSettings()
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Настройки делегации изменены'
          })
          if (participantsDone) this.fetchItems()
          if (withModal) {
            this.fetchItems()
            this.$refs.reviewSubmit.preload = false
            this.onHideModal()
          }
        })
    },
    onUpdateLimiter(data) {
      this.table_limit = data
      this.pagination.page = 1
      this.$refs.tableDefault.setLimit(this.table_limit)
      this.fetchItems()
    },
    onClickPagination(page) {
      this.pagination.page = page
      this.fetchItems()
    },
    onRowClick(row) {
      const routeData = this.$router.resolve({name: 'members-detail', params: {id: row.row.pk}})
      window.open(routeData.href, '_blank')
    },
    formatDate(date) {
      return dateFormatting(date, 'iso-to-normal-small')
    },
    getAge(ageStr) {
      return ageStr.split(' ')[0]
    },
    onSetDelegation() {
      this.delegation_name = this.delegation_options.find(item => item.id === this.user_delegation_id).name
      this.$store.commit('setDelegation', this.user_delegation_id)
      patchUser({ delegation: this.user_delegation_id })
        .then(response => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Делегация успешно изменена'
          })
          this.fetchItems()
          this.fetchDelegationsSettings()
          this.$store.commit('setDelegationHash', response.data.delegation_hash)
        })
    },
    onCopyLinkToClipboard() {
      const text = `${this.site_url}members/delegation/${this.$store.state.delegation_hash}`
      navigator.clipboard.writeText(text)
        .then(() => {
          this.$notify({
            type: 'success',
            text: 'Ссылка успешно скопирована'
          })
        })
    },
    onFilterSearch(search, loading) {
      loading(true)
      this.searchFilter(loading, search, this)
    },
    searchFilter: debounce((loading, search, vm) => {
      vm.fetchItems(1, vm.table_limit, search)
      loading(false)
    }, 350),
  }
}
</script>

